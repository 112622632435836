<template>
  <div v-frag>
    <v-container fluid>
      <v-row align='center' align-content='center' justify='center'>
        <v-col>
          <v-card class='rounded-lg' elevation='3'>
            <v-card-title class='grey--text mb-2'>
              <v-row>
                <v-col class='avatarCharity' lg='5' md='5' sm='12'>
                  <v-avatar color='grey' size='110'>
                    <v-img :lazy-src='charity.logo' :src='charity.logo'></v-img>
                  </v-avatar>
                  <p class='charity-name'>{{ charity.name }}</p>
                </v-col>
                <v-col lg='7' md='7' sm='12'>
                  <v-simple-table class='headerTable'>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class='text-center'>Total Founds Collected</th>
                        <th class='text-center border-left'>
                          Recurring Monthly Donations
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for='item in desserts' :key='item.name'>
                        <td class='text-center'>
                          {{ formatMoney(item.oneTime) }}
                        </td>
                        <td class='text-center border-left'>
                          {{ formatMoney(item.monthly) }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row align='center' align-content='center' justify='center'>
        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-card class='rounded-lg' elevation='3'>
            <v-card-title>
              <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                {{ sections.donation_history || 'Donation History title' }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols='12' lg='6' md='6' sm='12' xl='6' xs='12'>
                <v-text-field
                  v-model='search'
                  class='rounded-lg'
                  dense
                  filled
                  hide-details
                  placeholder='Search'
                  prepend-inner-icon='mdi-magnify'
                  rounded
                ></v-text-field>
              </v-col>
            </v-card-title>
            <v-container fluid>
              <v-data-table
                :headers='headers'
                :items='filterDate'
                :loading='loading'
                :search='search'
                class='elevation-1 data-table-border-radius'
                item-key='id'
              >
                <template v-slot:[`item.date`]='{ item }'>
                  <span> {{ formatToDate(item.date) }} </span>
                </template>
                <template v-slot:[`item.donation_amount`]='{ item }'>
                  <span> {{ formatMoney(item.donation_amount) }} </span>
                </template>
              </v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import donationsService from '../../services/donations/index'
import charityService from '../../services/charity/index'
import {mapState} from 'vuex'

export default {
  data() {
    return {
      search: '',
      loading: true,
      charity: {},
      oneTimeDonations: 0,
      monthlyDonations: 0,
      options: {},
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: false,
          value: 'date',
          divider: true
        },
        {text: 'Need', value: 'need', divider: true},
        {text: 'Employee', value: 'employee_name', divider: true},
        {text: 'Type', value: 'donation_recurrence', divider: true},
        {text: 'Amount', value: 'donation_amount', divider: true}
      ],
      desserts: [],
      requests: []
    }
  },
  computed: {
    ...mapState('cmsStore', ['sections']),
    filterDate() {
      return [...this.requests].sort((a, b) => new Date(b.date) - new Date(a.date))
    }
  },
  methods: {
    async initialize() {
      try {
        const topDesserts = {
          oneTime: 0,
          monthly: 0
        }
        const charityEvents = await donationsService.getDonations()
        this.desserts = []
        this.events = []
        charityEvents.forEach((charEvent) => {
          charEvent.charity_requests.forEach((charRequest) => {
            if (charRequest.donation_recurrence == 'One Time') {
              topDesserts.oneTime += charRequest.donation_amount
            } else {
              topDesserts.monthly += charRequest.donation_amount
            }
            this.requests.push({
              ...charRequest,
              need: charEvent.description
            })
          })
        })

        const charityQuery = await charityService.getProfile()

        this.desserts.push(topDesserts)
        this.charity = charityQuery
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
    getCharity() {
      this.$store.getters.charities.forEach((element) => {
        if (element.id == this.$route.params.id) {
          this.charity = element
        }
      })
    },
    openDialog() {
    },
    formatMoney: function(value) {
      if (typeof value == 'number') {
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })
      }
    },
    formatToDate: (value) => {
      if (value) {
        const date = new Date(value)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }
    }
  },
  created: function() {
    this.initialize()
    this.getCharity()
  }
}
</script>

<style>
.charity-name {
  word-wrap: break-word;
}
.avatarCharity {
  display: flex;
  justify-content: center;
}
.avatarCharity p {
  margin-left: 15px;
  line-height: 1.2;
  word-break: normal;
}
@media (min-width: 960px) and (max-width: 1024px) {
  .avatarCharity .v-avatar,
  .avatarCharity .v-avatar * {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
  }
  .avatarCharity p {
    font-size: 16px;
  }
}
.headerTable {
  border: 1px solid #eee;
}
.headerTable th td {
  text-align: center;
}
.border-left {
  border-left: 1px solid #eee;
}
.buttonHeader {
  display: flex;
  flex-direction: column;
  max-height: 5rem;
  margin-top: 1.5rem;
}
</style>
